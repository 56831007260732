/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect } from "react";
import { NewOnboardingStart, OnboardingData } from "../../components/PageSegments/onboarding";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { landed_from_promo } from "../../services/mixpanel/mixpanel";
import { BasicLayout } from "../../layouts/BasicLayout";

export default data => {
  const { search } = data.location;
  const { setPromoName, setCouponId } = useOnboardingContext();

  useEffect(() => {
    if (search) {
      const urlParams = new URLSearchParams(search);
      // new cookie will be expired in 1 year
      // var utm_date = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

      // for (const key of urlParams.keys()) {
      //   // set those parameter as cookies
      //   const value = urlParams.get(key);
      //   document.cookie = `${key}=` + value + "; expires=" + utm_date.toUTCString();
      // }

      const promo = urlParams.get("promo");
      const cid = urlParams.get("cid");
      if (promo) {
        setPromoName(promo);
        landed_from_promo(promo);
      }

      if (cid) {
        setCouponId(cid);
      }
    }
  });

  return (
    <BasicLayout>
      <NewOnboardingStart />
    </BasicLayout>
  );
};
